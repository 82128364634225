body {
  background-color: #f5f7fa;
  font-family: 'Open Sans', sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.button {
  background-color: #0270ba;
  color: #ffffff;
  border-radius: 10px;
  padding: 10px 20px;
  border: none;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:disabled {
  background-color: #979797;
}

.text {
  color: #000000;
  font-size: 20px;
  margin-bottom: 20px;
}
